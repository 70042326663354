.pum-theme-2589 .pum-container, .pum-theme-product-detail-theme .pum-container {
    background-color: white !important;
    padding: 3rem !important;
}

.pum-title  {
    font-weight:700;
}
.pum-container .pum-title {
    font-weight:700 !important;
}
.pum-close.popmake-close {
    font-weight: 600 !important;
}
.pum-content {
    .pcontact-form-list {
        .ip-control {
            margin-bottom: 2rem;

            label {
                color: #000 !important;
                font-weight:700 !important;
            }
        }
    }
    .wpcf7-not-valid-tip, form .wpcf7-not-valid-tip {
        bottom: -25px !important;
    }
}